* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'LapsusBold';
  src: local('Akira'), url(assets/fonts/LapsusPro-Bold.woff2) format('woff');
}

body {
  margin: 0;
  background-color: black;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f500;
}

::-webkit-scrollbar-thumb {
  /* border-radius: 10px;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.9, white), color-stop(0.7, #00a1e6)); */
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.recharts-wrapper {
  border-radius: 10px;
  background: #252525ad;
  font-family: LapsusBold;
}

.recharts-text .recharts-cartesian-axis-tick-value {
  dy: -3;
}